
export const greyscales = [
    '#ffffff',
    '#f6f6f6',
    '#e4e4e4',
    '#b6b6b6',
    '#929292',
    '#686868',
    '#454545',
    '#393939',
];

export const colors = [
    '#fbd1bf',
    '#cb977f',
    '#db6f56',
    '#f0d0c8',
    '#c19877',
    '#956d4d',
    '#633728',
    '#5b3735',
    '#eb9840',
    '#e2deb5',
    '#f7e1e1',
    '#7e675f',
    // weird colors below
    '#eee476',
    '#87db73',
    '#90daef',
    '#d37b2d',
    '#f7738c',
    '#e24261',
    '#b582d6',
    '#bab163',
    '#a8d676',
    '#698b98',
    '#58666b',
    '#66586b',
    // greyscales
    '#e4e4e4',
    '#b6b6b6',
    '#929292',
    '#686868',
    '#454545',
    '#393939',
    // more weird colors
    '#5ff892',
    '#5ec9c5',
    '#fcc919',
    '#30a4ee',
    '#d651ee',
    '#fa427a',
    '#6554ce',
    '#433b73',
    '#683b73',
    '#183f3a',
    '#3e593e',
    '#c3e0c1',
];
